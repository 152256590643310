<template>
    <dialog id="mic-permission-dialog" v-show="isOpen" ref="dialogRef" class="mic-permission-aide">
        <div class="mic-permission-aide-image">
            <svg class="h-full w-full" viewBox="0 0 381 227" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.625977" y="43" width="369" height="180" fill="#F0F2F9" />
                <rect x="0.625977" width="369" height="43" fill="#E8EAED" />
                <rect x="6.62598" y="7" width="8" height="8" rx="4" fill="#D9D9D9" fill-opacity="0.5" />
                <rect x="18.626" y="7" width="8" height="8" rx="4" fill="#D9D9D9" fill-opacity="0.5" />
                <rect x="29.626" y="7" width="8" height="8" rx="4" fill="#D9D9D9" fill-opacity="0.5" />
                <rect x="0.625977" y="22" width="369" height="21" fill="white" />
                <rect x="45.626" y="3" width="122" height="35" rx="10" fill="white" />
                <rect x="50.626" y="8" width="12" height="12" rx="6" fill="#D9D9D9" fill-opacity="0.5" />
                <rect x="65.626" y="10" width="94" height="8" rx="4" fill="#D9D9D9" fill-opacity="0.5" />
                <rect x="43.626" y="27" width="12" height="12" rx="6" fill="#D9D9D9" fill-opacity="0.5" />
                <rect x="349.626" y="27" width="12" height="12" rx="6" fill="#D9D9D9" fill-opacity="0.5" />
                <rect x="63.626" y="27" width="260" height="12" rx="6" fill="#D9D9D9" fill-opacity="0.5" />
                <rect x="24.626" y="27" width="12" height="12" rx="6" fill="#D9D9D9" fill-opacity="0.5" />
                <rect x="330.626" y="27" width="12" height="12" rx="6" fill="#D9D9D9" fill-opacity="0.5" />
                <rect x="6.62598" y="27" width="12" height="12" rx="6" fill="#D9D9D9" fill-opacity="0.5" />
                <rect x="89.626" y="51" width="170" height="106" fill="white" />
                <rect x="172.626" y="133" width="33" height="17" fill="#D9D9D9" fill-opacity="0.5" />
                <rect x="211.626" y="133" width="37" height="17" fill="#005EEA" />
                <rect x="98.626" y="59" width="146" height="10" rx="5" fill="#D9D9D9" fill-opacity="0.5" />
                <rect x="98.626" y="74" width="146" height="10" rx="5" fill="#D9D9D9" fill-opacity="0.5" />
                <rect x="98.626" y="94" width="92" height="10" rx="5" fill="#D9D9D9" fill-opacity="0.5" />
                <rect x="98.626" y="108" width="92" height="10" rx="5" fill="#D9D9D9" fill-opacity="0.5" />
            </svg>
        </div>
        <div class="mic-permission-aide-text">
            <div class="text-xl font-semibold tracking-tighter text-[#262626]">Allow microphone?</div>
            <div class="mt-4 text-base font-medium leading-normal tracking-tighter text-[#8C8C8C]">
                Please choose the &ldquo;Allow&rdquo; button in the pop up window to enable microphone permissions.
            </div>
            <button type="button" @click="handleDialogCancel" class="mt-4 button-text">I&apos;d rather type</button>
        </div>
        <div class="mic-permission-aide-swirl">
            <svg class="h-full w-full" viewBox="0 0 144 163" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M141.222 133.957C128.494 135.191 92.2209 139.13 86.2725 120.521C83.7973 112.778 84.7853 100.746 89.9225 93.96C100.954 79.3866 127.961 87.65 112.291 106.003C103.502 116.296 86.5005 114.413 74.4797 112.914C56.7602 110.704 45.7508 100.053 35.0774 86.7539C29.657 80 11.7461 43.1886 14.3442 43.7204"
                    stroke="#FF2891"
                    stroke-width="5"
                    stroke-linecap="round"
                />
                <path
                    d="M5.98114 31.0536C8.63485 31.6939 28.7938 37.2514 26.4228 36.2511C23.3196 34.9418 19.9829 33.912 17.062 32.2256C13.6063 30.2304 9.70111 29.1371 6.4913 26.7758C3.6747 24.7037 4.23724 43.0913 5.07165 46.2053"
                    stroke="#FF2891"
                    stroke-width="5"
                    stroke-linecap="round"
                />
            </svg>
        </div>
    </dialog>
</template>

<script setup>
import { onMounted, onUnmounted, useTemplateRef, watch } from "vue";

const emit = defineEmits(["cancel"]);
const props = defineProps({
    isOpen: Boolean,
});

const dialogRef = useTemplateRef("dialogRef");

watch(
    () => props.isOpen,
    (value) => {
        if (value) {
            dialogRef.value.showModal();
        } else {
            dialogRef.value.close();
        }
    },
);

onMounted(() => {
    if (dialogRef.value) {
        dialogRef.value.addEventListener("cancel", handleDialogCancel);
    }
});

onUnmounted(() => {
    if (dialogRef.value) {
        dialogRef.value.removeEventListener("cancel", handleDialogCancel);
    }
});

function handleDialogCancel() {
    emit("cancel");
}
</script>

<style type="postcss">
.mic-permission-aide {
    @apply grid rounded-3xl max-w-2xl w-1/2 ml-6 mt-4 bg-white p-4 w-full;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(9, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.mic-permission-aide-image {
    grid-area: 1 / 3 / 7 / 7;
}

.mic-permission-aide-text {
    @apply text-center text-balance;
    grid-area: 6 / 2 / 10 / 8;
}

.mic-permission-aide-swirl {
    grid-area: 1 / 1 / 4 / 3;
}

dialog.mic-permission-aide::backdrop {
    background: rgba(23, 55, 100, 0.4);
    backdrop-filter: blur(4px);
}
</style>
