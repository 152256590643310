<template>
    <Transition name="fade">
        <PermissionDialog :is-open="showMicPermissionAide" @cancel="handleDialogCancel" />
    </Transition>
    <div class="stacked-grid">
        <div :class="cardClass" class="stacked-grid-item">
            <template v-if="isCustomContent">
                <slot name="custom-content"></slot>
            </template>
            <template v-else-if="isAnswering">
                <div v-if="isQuestionPositionShown" class="text-center tracking-tighter font-semibold text-[#8C8C8C]">{{ currentQuestionPosition }} of {{ questionsCount }}</div>
                <h2 class="text-xl font-regular leading-tight text-[#262626]">{{ currentQuestion.text }}</h2>
                <div v-show="usingText" class="child-focus transition-colors w-full flex items-center gap-3 rounded-xl border-2 border-[#E8E8E8] px-6 py-10">
                    <textarea
                        ref="textareaRef"
                        v-model="text"
                        placeholder="Your answer here..."
                        :disabled="disabled"
                        class="bg-white grow disabled:opacity-75 focus:outline-none text-[#555BA2] text-lg font-regular border-0"
                    ></textarea>
                    <button type="button" title="Submit" :disabled="disabled" class="shrink-0 text-3xl text-[#555BA2] hover:text-[#4B508F] disabled:opacity-50" @click="handleText">
                        <i class="bi bi-arrow-right-circle" />
                    </button>
                </div>
                <Transition name="slide">
                    <div v-if="!usingText && isRecording" class="overflow-y-clip min-h-8 h-auto origin-bottom flex items-center gap-[6px] p-4 mx-auto">
                        <template v-for="(level, idx) in levelHistorySlice" :key="idx">
                            <div :style="levelHeight(level)" class="w-[3px] h-8 transition-transform duration-200 ease-linear rounded-full bg-[#555BA2]"></div>
                        </template>
                    </div>
                </Transition>
                <div class="mx-auto flex flex-col gap-2">
                    <template v-if="!usingText">
                        <template v-if="isRecording">
                            <button type="button" class="button button-danger" @click="stopRecording">Stop recording</button>
                            <button type="button" class="button button-text" @click="cancelRecording">Cancel</button>
                        </template>
                        <template v-else>
                            <button
                                :disabled="isTranscribing || disabled"
                                type="button"
                                class="button button-primary"
                                @click="startRecording"
                                v-text="isTranscribing ? 'Transcribing...' : 'Share my thoughts'"
                            ></button>
                            <button v-if="supportsVoice && !isTranscribing" type="button" :disabled="disabled" class="button button-text" @click="usingText = true">
                                Switch to text
                            </button>
                        </template>
                    </template>
                    <template v-else>
                        <button v-if="supportsVoice" type="button" :disabled="disabled" class="button button-text" @click="usingText = false">Switch to voice</button>
                    </template>
                </div>
            </template>
            <template v-else>
                <div class="text-center font-semibold tracking-tighter text-2xl" :class="currentQuestion.offTopic ? 'text-[#262626]' : 'text-[#555BA2]'">
                    Here&apos;s what I heard
                </div>
                <div class="text-xl font-regular leading-tight text-[#262626] max-h-64 overflow-y-auto whitespace-pre-line" v-text="questionAnswer"></div>
                <div class="mx-auto flex flex-col gap-3">
                    <button
                        v-if="!currentQuestion.offTopic"
                        type="button"
                        :disabled="disabled"
                        class="button button-primary"
                        @click="handleNextQuestion"
                        v-text="nextText"
                    ></button>
                    <button type="button" :disabled="disabled" class="button" :class="currentQuestion.offTopic ? 'button-primary' : 'button-text'" @click="handleMoreContext">
                        <i v-if="!usingText" class="bi bi-mic" />Give more context
                    </button>
                </div>
            </template>
        </div>
        <div class="rotate-[6deg] w-full h-full stacked-grid-item stacked-grid-item--question"></div>
        <div class="rotate-[-4deg] w-full h-full stacked-grid-item stacked-grid-item--question"></div>
    </div>
    <div v-if="!hideSteps" class="bg-[#F5F5F5] rounded-full p-4 flex items-center gap-3">
        <template v-for="step in questionsCount * 2" :key="step">
            <Transition mode="out-in" name="expand">
                <div v-if="isAtStep(step)" class="indicator indicator--active"></div>
                <div v-else class="indicator"></div>
            </Transition>
        </template>
    </div>
</template>

<script setup>
import { AudioRecorder } from "/js/AudioRecorder.js";
import { encodeAudio, transcribeRecording } from "/js/transcription.js";
import { logUserInteraction } from "~vue/utils/logUtils";
import { computed, inject, nextTick, onMounted, ref, useTemplateRef, watch } from "vue";

import PermissionDialog from "./PermissionDialog.vue";

const LEVELS_ELEMENTS = 24;

const props = defineProps({
    disabled: { type: Boolean, default: false },
    currentQuestion: { type: Object, required: true },
    currentQuestionPosition: { type: Number, default: 1 },
    questionsCount: { type: Number, default: 1 },
    hideSteps: { type: Boolean, default: false },
    isCustomContent: { type: Boolean, default: false },
    isVoiceEnabled: { type: Boolean, default: true },
    isQuestionPositionShown: { type: Boolean, default: true },
});

const emit = defineEmits(["answer", "next", "recording-start", "recording-stop", "recording-level", "transcribe-start", "transcribe-end", "transcribe-error", "using-text"]);

const { $sendEvent } = inject("globalProperties");

const STATE = {
    QUESTION: "question",
    RECORDING: "recording",
    TRANSCRIBING: "transcribing",
    ANSWERED: "answered",
};

const supportsVoice = ref(false);
const usingText = ref(!props.isVoiceEnabled);
const text = ref("");
const textareaRef = useTemplateRef("textareaRef");
const state = ref(STATE.QUESTION);
const showMicPermissionAide = ref(false);
const recorder = ref(null);
const levelHistory = ref([]);

const questionAnswer = computed(() => {
    if (props.currentQuestion.offTopic) {
        return "Off-topic conversation.";
    }

    return props.currentQuestion.answer;
});

const nextText = computed(() => {
    if (props.currentQuestionPosition === props.questionsCount) {
        return "Next";
    }
    return "Next question";
});

const levelHistorySlice = computed(() => levelHistory.value.slice(-LEVELS_ELEMENTS));
const makeComputedState = (states) => computed(() => states.includes(state.value));
const isRecording = makeComputedState([STATE.RECORDING]);
const isTranscribing = makeComputedState([STATE.TRANSCRIBING]);
const isAnswering = makeComputedState([STATE.QUESTION, STATE.RECORDING, STATE.TRANSCRIBING]);

const cardClass = computed(() => {
    if (isAnswering.value) {
        return "stacked-grid-item--question";
    }

    return props.currentQuestion.offTopic ? "stacked-grid-item--offtopic" : "stacked-grid-item--answer";
});

watch(usingText, (value) => {
    if (value && textareaRef.value) {
        emit("using-text");
        nextTick(() => textareaRef.value.focus());
    }
});

watch(
    () => props.currentQuestion,
    () => {
        state.value = STATE.QUESTION;
        text.value = "";

        if (usingText.value) {
            nextTick(() => textareaRef.value.focus());
        }
    },
);

onMounted(() => {
    initializeLevelsHistory();
});

function handleDialogCancel() {
    showMicPermissionAide.value = false;
    usingText.value = true;
}

function initializeLevelsHistory() {
    levelHistory.value = Array.apply(null, Array(LEVELS_ELEMENTS)).map(() => 0.5);
}

function wait(ms) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

async function startRecording({ append = false } = {}) {
    try {
        const micPermission = await navigator.permissions.query({ name: "microphone" });

        if (micPermission.state === "prompt") {
            showMicPermissionAide.value = true;
            await wait(1000);
        } else if (micPermission.state === "granted") {
            supportsVoice.value = true;
        } else if (micPermission.state === "denied") {
            supportsVoice.value = false;
        }
    } catch (e) {
        /*
         * Querying existing mic permissions is a nice to have, and does not
         * work on Firefox (an exception is raised). We can silently pass here.
         */
    }

    recorder.value = new AudioRecorder({ levelCheckInteralMs: 16 });
    recorder.value.on("level", handleRecorderLevel);
    recorder.value.on("finish", (data) => handleRecorderFinished({ ...data, append }));
    recorder.value.on("start", handleRecorderStart);
    recorder.value.on("stop", handleRecorderStop);

    try {
        await recorder.value.start();
        supportsVoice.value = true;
    } catch (e) {
        supportsVoice.value = false;
        usingText.value = true;
    } finally {
        if (showMicPermissionAide.value) {
            showMicPermissionAide.value = false;
        }
    }
}

function stopRecording() {
    recorder.value.stop();
}

function cancelRecording() {
    recorder.value.stop({ abort: true });
    state.value = STATE.QUESTION;
    emit("recording-stop");
}

function handleText() {
    emit("answer", {
        answer: text.value,
        append: false,
        onFinish: () => (state.value = STATE.ANSWERED),
    });
}

function handleRecorderStart() {
    state.value = STATE.RECORDING;
    emit("recording-start");
}

async function handleRecorderFinished({ blob, append }) {
    recorder.value = null;
    initializeLevelsHistory();
    state.value = STATE.TRANSCRIBING;

    const encodedAudio = await encodeAudio(blob);
    emit("transcribe-start");
    const transcript = await transcribeRecording({
        encodedAudio,
        sendEvent: $sendEvent,
        onError: () => {
            emit("transcribe-error");
            if ("Sentry" in window) {
                window.Sentry.captureException(new Error("Transcription request failed"));
            }
        },
    });
    emit("transcribe-end");

    emit("answer", {
        answer: transcript,
        append,
        onFinish: () => (state.value = STATE.ANSWERED),
    });
}

function handleRecorderStop() {
    emit("recording-stop");
}

function handleNextQuestion() {
    emit("next");
}

function handleMoreContext() {
    if (usingText.value) {
        state.value = STATE.QUESTION;
        textareaRef.value.focus();
    } else {
        startRecording({ append: true });
    }

    logUserInteraction("give_more_context_clicked", {}, true);
}

function handleRecorderLevel(level) {
    // Add some gain
    const normalized = level * 500;

    if (normalized >= 100) {
        levelHistory.value.push(1);
    } else if (normalized < 100 && normalized >= 75) {
        levelHistory.value.push(0.75);
    } else if (normalized < 75 && normalized >= 50) {
        levelHistory.value.push(0.5);
    } else if (normalized < 50) {
        levelHistory.value.push(0.25);
    }

    emit("recording-level", { level, isBelowSilenceThreshold: recorder.value.isBelowSilenceThreshold(level) });
}

function levelHeight(level) {
    switch (level) {
        case 1:
            return "transform: scaleY(2)";
        case 0.75:
            return "transform: scaleY(1.5)";
        default:
        case 0.5:
            return "transform: scaleY(1)";
    }
}

function isAtStep(stepNumber) {
    /*
     * The amount of progress indicators is the total amount
     * of questions multiplied by two, as each question answer has its own
     * step. To derive which step the user's we only have to check whether
     * the state of the card is in the answer state. If so, we can simply
     * multiply the current question position times two. If the card is not
     * in the answer state, we substract 1 for the answer step.
     */

    if (state.value === STATE.ANSWERED) {
        return stepNumber === props.currentQuestionPosition * 2;
    } else {
        return stepNumber === props.currentQuestionPosition * 2 - 1;
    }
}
</script>

<style scoped>
.stacked-grid {
    @apply grid place-items-center max-w-md mx-auto justify-stretch w-full;
    grid-template-areas: "stacked";
}

.stacked-grid-item {
    @apply border-2 px-6 py-10 rounded-2xl flex flex-col gap-6 transition-all duration-300 ease-in-out text-center w-full;
    grid-area: stacked;
}

.stacked-grid-item:first-child {
    @apply z-20;
}

.stacked-grid-item:not(:last-child) {
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.02);
}

.stacked-grid-item--question {
    @apply bg-white border-[#F5F5F5] gap-6;
}

.stacked-grid-item--answer,
.stacked-grid-item--offtopic {
    @apply gap-10;
}

.stacked-grid-item--answer {
    @apply bg-[#F0F5FF] border-[#E0E9FF];
}

.stacked-grid-item--offtopic {
    @apply bg-[#F5F5F5] border-[#E8E8E8];
}

.indicator {
    @apply h-1 w-1;
}

.indicator:not(.indicator--active) {
    @apply bg-[#BFBFBF] rounded-full;
}

.indicator--active {
    @apply bg-[#8C8C8C] mx-2 origin-center;
    transform: scaleX(6);
    border-radius: 0.75px;
}

.child-focus:has(textarea:focus) {
    border-color: #555ba2;
}

.slide-enter-active,
.slide-leave-active {
    transition: transform 0.5s ease;
}

.slide-enter-from,
.slide-leave-to {
    transform: scaleY(0);
}

.expand-enter-active,
.expand-leave-active {
    transition: transform 0.3s ease;
}

.expand-enter-from,
.expand-leave-to {
    transform: scaleX(1);
}
</style>
