<template>
    <dialog v-show="isOpen" ref="dialogRef" class="privacy-dialog">
        <div class="text-center flex flex-col gap-3 items-center tracking-tighter">
            <div><i class="bi bi-lock text-2xl" /></div>
            <div class="font-semibold text-2xl text-[#262626]">Your conversations are completely private</div>
            <div class="font-medium text-base text-[#8C8C8C] w-96 mx-auto">
                I never share information with your company.
                <a ref="external" class="hover:text-[#4B508F] text-[#555BA2]" href="https://www.valence.co/other/bill-of-rights" target="_blank">Learn more</a> about how seriously
                we take privacy.
            </div>
        </div>
        <div class="inline-block mx-auto">
            <button :autofocus="isOpen" type="button" class="button button-primary" @click="closeDialog">Dismiss</button>
        </div>
    </dialog>
    <button type="button" :disabled="disabled" class="button button-text button-text--muted" @click="openDialog">Private <i class="bi bi-lock" /></button>
</template>

<script setup>
import { onMounted, onUnmounted, ref, useTemplateRef, watch } from "vue";

defineProps({
    disabled: Boolean,
});
const isOpen = ref(false);
const dialogRef = useTemplateRef("dialogRef");

watch(isOpen, (value) => {
    if (value) {
        dialogRef.value.showModal();
    } else {
        dialogRef.value.close();
    }
});

onMounted(() => {
    if (dialogRef.value) {
        dialogRef.value.addEventListener("cancel", handleDialogCancel);
    }
});

onUnmounted(() => {
    if (dialogRef.value) {
        dialogRef.value.removeEventListener("cancel", handleDialogCancel);
    }
});

function handleDialogCancel(e) {
    e.preventDefault();
    closeDialog();
}

function openDialog() {
    isOpen.value = true;
}

function closeDialog() {
    isOpen.value = false;
}
</script>

<style scoped type="postcss">
.privacy-dialog {
    @apply rounded-3xl bg-white px-6 py-10 mx-auto max-w-md w-full flex flex-col gap-10 items-center;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
}

dialog.privacy-dialog::backdrop {
    background: rgba(23, 55, 100, 0.4);
    backdrop-filter: blur(4px);
}
</style>
